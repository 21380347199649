import * as React from "react"
import { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import { ProductListing } from "../../components/product-listing"
import ShopHeader from "../../components/shop-header"
import { Seo } from "../../components/seo"

const Shop = ({ location, data: { products } }) => {

  const [productList, setProductList] = useState(products.nodes)
  const [currentSort, setCurrentSort] = useState("new")

  function asc(a,b) {return parseFloat(a.priceRangeV2.minVariantPrice.amount) - parseFloat(b.priceRangeV2.minVariantPrice.amount);}
  function desc(a,b) {return parseFloat(b.priceRangeV2.minVariantPrice.amount) - parseFloat(a.priceRangeV2.minVariantPrice.amount);}
  function newArrivals(a,b) {return Date.parse(b.publishedAt) - Date.parse(a.publishedAt)}

  const sortByNewArrivals = () => {
    setProductList(products.nodes.sort(newArrivals))
    setCurrentSort("new")
  }
  const sortByLowToHigh = () => {
    setProductList(products.nodes.sort(asc))
    setCurrentSort("lowToHigh")
  }
  const sortByHighToLow = () => {
    setProductList(products.nodes.sort(desc))
    setCurrentSort("highToLow")
  }

  return (
    <Layout containerStyle={"noLanding"}>
      <Seo title="Merch" />
      <ProductListing products={productList} />
      <ShopHeader
        currentPath={location.pathname} 
        newArrivals={sortByNewArrivals}
        lowToHigh={sortByLowToHigh}
        highToLow={sortByHighToLow}
        currentSort={currentSort}
      />
    </Layout>
  )
}
export default Shop

export const query = graphql`
  {
    products: allShopifyProduct(
      filter: {collections: {elemMatch: {title: {eq: "Merch"}}}}
      sort: { fields: publishedAt, order: DESC }
    ) {
      nodes {
        publishedAt
        ...ProductCard
      }
    }
  }
`